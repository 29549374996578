<template>
  <el-popover v-model="popShow" popper-class="pop-select-org" placement="bottom-start" trigger="click" >
    <el-input class="org-input" slot="reference" :value="keyword" :placeholder="placeholder" :clearable="true" @input="onChange" @blur="onBlur" @clear="onClear">
       <i v-if="!orgList.length" slot="prefix" class="h5pp h5pp-icon-org"></i>
    </el-input>
    <el-tree ref="tree" :default-checked-keys="!Array.isArray(value) ? [value] : value" :filter-node-method="filterNode" :data="options" node-key="id" default-expand-all :check-strictly="true" :show-checkbox="isMutiple" :expand-on-click-node="false" @node-click="onSelect" @check="onCheck">
      <div class="custom-select-tree-node" :class="{'check-tree-node': isMutiple }" slot-scope="{ data }">
        <div class="tree-item-label" :title="data.label">
          <i v-if="!data.id" class="apollo ao-icon-company2"></i>
          <span class="el-sl">{{ data.label }}</span>
        </div>
      </div>
    </el-tree>
  </el-popover>
</template>

<script>
  import './index.scss';
  import { Toast } from 'vant'
  import { Popover, Tree, Input } from 'element-ui'

  export default {
    components: {
      [Popover.name]: Popover,
      [Tree.name]: Tree,
      [Input.name]: Input
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      id: [Number, String],
      value: [Number, String, Array],
      maxLength: {
        type: Number,
        default: 5
      },
      placeholder: {
        type: String,
        default: '请选择部门'
      },
      isMutiple: {
        type: Boolean,
        default: false
      },
      showLevel: {
        type: [Number, String],
        default: ''
      },
      orgList: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        popShow: false,
        keyword: '',
        currentKey: '',
        optionObjs: {},
        options: [],
        checkedOrgIds: [],
        checkedOrgNames: []
      };
    },
    created() {
      if(this.id) {
        this.queryOrganizations();
      }
    },
    methods: {
      queryOrganizations() {
        const loop = (arr, level = 0) => {
          if(!arr) arr = [];
          return arr.map(item => ({
            label: item.name,
            id: item.id,
            level,
            disabled: !!!level, 
            children: level > 3 ? null : loop(item.children, level + 1)
          }));
        }
        if(this.id) {
          this.$http.get('/api/company/org/list.json', {params: {
            websiteId: this.id
          }}).then(data => {
            this.options = loop(data);
            this.optionObjs = this.$utils.repeat(this.options, 'id');
            this.onBlur();
          });
        } else {
          this.options = loop(this.orgList);
          this.optionObjs = this.$utils.repeat(this.options, 'id');
          this.onBlur();
        }
      },
      onBlur() {
        if(Array.isArray(this.value)) {
          this.checkedOrgNames = [];
          this.keyword = this.value.map(item => {
            this.checkedOrgNames.push({ id: item, label: (this.optionObjs[item] || {}).label || ''});
            return `${(this.optionObjs[item] || {}).label || ''}`
          }).join('，');
        } else {
         this.keyword = (this.optionObjs[this.value] || {}).label || '';
        }
      },
      onChange(val) {
        this.keyword = val;
        this.$refs.tree.filter(this.keyword);
      },
      onClear() {
        this.$emit('change', '');
      },
      filterNode(value, data) {
        if(!value) return true;
        return data.label.indexOf(value) !== -1;
      },
      onSelect(obj) {
        if(!obj.id) {
          return;
        }
        if(this.isMutiple) {
          if(this.maxLength <= this.checkedOrgNames.length && !this.checkedOrgNames.map(item => item.id).includes(obj.id)) {
            Toast.fail(`最多选择${this.maxLength}个`);
          } else {
            if(!this.checkedOrgNames.map(item => item.id).includes(obj.id)) {
              this.checkedOrgNames.push(obj);
            } else {
              this.checkedOrgNames.forEach((item, index) => {
                if(item.id === obj.id) {
                  this.checkedOrgNames.splice(index, 1);
                }
              });
            }
            this.checkedOrgIds = this.checkedOrgNames.map(item => item.id);
            this.keyword = this.checkedOrgNames.map(item => item.label).join('，');
            this.$emit('change', this.checkedOrgIds);
            this.$refs.tree.setCheckedKeys(this.checkedOrgIds, true);
          }
        } else {
          this.$emit('change', obj.id);
          this.keyword = obj.label;
          this.popShow = false;
        }
      },
      onCheck(checkedNodes, checkedKeys) {
        if(this.maxLength <= this.checkedOrgIds.length && checkedKeys.checkedKeys.includes(checkedNodes.id)) {
          Toast.fail(`最多选择${this.maxLength}个`);
        } else {
          this.checkedOrgIds = checkedKeys.checkedKeys;
          this.checkedOrgNames = checkedKeys.checkedNodes;
          this.keyword = this.checkedOrgNames.map(item => item.label).join('，');
          this.$emit('change', this.checkedOrgIds);
        }
        this.$refs.tree.setCheckedKeys(this.checkedOrgIds, true);
      }
    },
    watch: {
      orgList: {
        handler(val) {
          if(val.length){
            this.queryOrganizations();
            return val;
          }
        },
        deep: true
      }
    }
  };
</script>
