<template>
  <div class="head-nav-box">
    <van-nav-bar v-if="title" left-arrow :title="title" @click-left="onBack" :style="{'background-color': NavBgColor[navBarBgColor]}">
      <template v-if="hasRightMenus" #right>
        <a href="javascript:;" class="h5pp h5pp-icon-menu" @click="showNav=true"></a>
      </template>
    </van-nav-bar>
    <van-nav-bar v-else class="home-nav-bar" :class="{'search-box-show': searchBoxShowType === 2 && isSearch}" :style="{'background-color': NavBgColor[navBarBgColor]}" >
      <template v-if="uccnInfo && uccnInfo.logoUrl && !isSearch" #title>
        <!-- 永福LOGO -->
        <img v-if="[1029, 1027].includes(uccnInfo.id)" src="https://hy-awa-ats.oss-cn-hangzhou.aliyuncs.com/website/ecad6e9a91fb7dfdf49f30c0683b2150.png" class="img-logo yongfu">
        <img v-else :src="uccnInfo.logoUrl" class="img-logo">
      </template>
      <template v-if="hasRightMenus || searchBoxShowType === 2" #right>
        <template v-if="searchBoxShowType === 2">
          <van-search v-if="isSearch" class="input-search" v-model.trim="keyWord" placeholder="搜索职位关键字" @keyup.enter="onSearch" @blur="onSearch"></van-search>
          <a v-else href="javascript:;" class="h5pp h5pp-icon-search" @click="isSearch = !isSearch" />
        </template>
        <a v-if="hasRightMenus" href="javascript:;" class="h5pp h5pp-icon-menu" @click="showNav=true"></a>
      </template>
    </van-nav-bar>
    <!-- 挂载到 #app 节点下 -->
    <van-popup v-model="showNav" class="popup-head-navs" position="right" get-container="#app">
      <sub-nav @closeNav="onCloseNav"></sub-nav>
    </van-popup>
  </div>
</template>

<script>
  import './index.scss'
  import { NavBar, Popup, Search, Icon } from 'vant'
  import { mapGetters } from 'vuex'
  import { NavBgColor } from '@/enums/EnumBase'
  import subNav from '@/components/h5/navigate'

  export default {
    components: {
      [NavBar.name]: NavBar,
      [Popup.name]: Popup,
      [Search.name]: Search,
      [Icon.name]: Icon,
      subNav
    },
    props: {
      eventBack: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      hasRightMenus: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        NavBgColor,
        keyWord: '',
        showNav: false,
        isSearch: false
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      navBarBgColor() {
        if(this.uccnInfo && this.uccnInfo.moduleDtoList && this.uccnInfo.moduleDtoList.length && this.uccnInfo.moduleDtoList.find(item => item.type===1) && this.uccnInfo.moduleDtoList.find(item => item.type===1).moduleContentList && this.uccnInfo.moduleDtoList.find(item => item.type===1).moduleContentList.length) {
          return this.uccnInfo.moduleDtoList.find(item => item.type===1).moduleContentList[0].navBarBgColor;
        }
      },
      searchBoxShowType() {
        if(this.uccnInfo && this.uccnInfo.moduleDtoList && this.uccnInfo.moduleDtoList.length && this.uccnInfo.moduleDtoList.find(item => item.type===1) && this.uccnInfo.moduleDtoList.find(item => item.type===1).moduleContentList && this.uccnInfo.moduleDtoList.find(item => item.type===1).moduleContentList.length) {
          return this.uccnInfo.moduleDtoList.find(item => item.type===1).moduleContentList[0].searchBoxShowType;
        }
      }
    },
    methods: {
      onBack() {
        if(this.eventBack) {
          this.$emit('goBack')
        } else {
          this.$router.back()
        }
      },
      onCloseNav(id) {
        this.showNav = false;
        if(id) {
          location.href = `/${this.uccnInfo.websiteUrl}/home#${id}`;
        }
      },
      onSearch() {
        this.$router.push({
          name: 'jobList',
          query: { 
            keyWord: this.keyWord,
            websiteId: this.uccnInfo.id
          }
        });
      },
    },
    watch: {
      'uccnInfo.websiteTitle'(val) {
        if(val) {
          window.document.title = val;
        }
      }
    }
  }
</script>