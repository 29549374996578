<template>
  <el-dialog :visible.sync="dialogData.isShow" :close-on-click-modal="false" class="certify-email-modal">
    <div class="certify-box">
      <i class="h5pp h5pp-icon-done"></i>
      <span>认证邮件已发送至企业邮箱</span>
      <span>请点击邮件中的链接进行验证</span>
    </div>
    <a href="javascript:;" slot="footer" @click="onRefresh" :loading="isBtnLoading" class="dialog-footer">
      已验证，点击刷新
    </a>
  </el-dialog>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Dialog, Message } from 'element-ui'

  export default {
    props: {
      dialogData: Object
    },
    components: {
      [Dialog.name]: Dialog,
      [Message.name]: Message
    },
    data() {
      return {
        isBtnLoading: false
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    methods: {
      onRefresh() {
        this.isBtnLoading= true;
        this.$http.get('/api/internal/recom/person/flush.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data.personId) {
            this.$store.commit('SET_USER_INFO', data);
            if(data.checkFlag) {
              this.$emit('successIdentityNews');
            } else {
              location.reload();
            }
            this.dialogData.isShow = false;
          } else{
            Message.error('暂未认证，请前往邮箱认证')
          }
        }).finally(() => {
          this.isBtnLoading= false;
        });
      }
    }
  }
</script>
