<template>
  <div>
    <popup-login v-if="loginObj.isShow" :dialogData="loginObj" />
    <popup-identity v-if="identityObj.isShow" :dialogData="identityObj" @onSelectRole="onSelectRole" />
    <popup-auth-ambassador v-if="authAmbassadorObj.isShow" :dialogData="authAmbassadorObj" @success="loginObj.isShow = true" />
    <popup-employee v-if="employeeObj.isShow" :dialogData="employeeObj" @successEmail="certifyEmailObj.isShow = true" @successIdentityNews="newsObj.isShow = true" />
    <popup-out-user v-if="outUserObj.isShow" :dialogData="outUserObj" />
    <popup-news v-if="newsObj.isShow" :dialogData="newsObj" />
    <popup-certify-email v-if="certifyEmailObj.isShow" :dialogData="certifyEmailObj" @successIdentityNews="newsObj.isShow = true" />
  </div>
</template>
<script>
  import popupLogin from '@/components/pc/dialog/login'
  import popupIdentity from '@/components/pc/dialog/identity'
  import popupAuthAmbassador from '@/components/pc/dialog/authAmbassador'
  import popupEmployee from '@/components/pc/dialog/employee'
  import popupOutUser from '@/components/pc/dialog/outUser'
  import popupNews from '@/components/pc/dialog/news'
  import popupCertifyEmail from '@/components/pc/dialog/certifyEmail'

  export default {
    components: {
      popupLogin,
      popupIdentity,
      popupAuthAmbassador,
      popupEmployee,
      popupOutUser,
      popupNews,
      popupCertifyEmail
    },
    props: {
      loginObj: {
        type: Object,
        default: () => ({
          isShow: false
        })
      },
      identityObj: {
        type: Object,
        default: () => ({
          isShow: false
        })
      },
      authAmbassadorObj: {
        type: Object,
        default: () => ({
          isShow: false
        })
      },
      employeeObj: {
        type: Object,
        default: () => ({
          isShow: false
        })
      }
    },
    data() {
      return {
        // employeeObj: {
        //   isShow: false
        // },
        outUserObj: {
          isShow: false
        },
        newsObj: {
          isShow: false
        },
        certifyEmailObj: {
          isShow: false
        }
      }
    },
    methods: {
      onSelectRole(flag) {
        if(flag) {
          this.employeeObj.isShow = true;
        } else {
          this.outUserObj.isShow = true;
        }
      }
    }
  }
</script>
