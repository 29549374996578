<template>
  <div class="navigate-wrap">
    <ul v-if="uccnInfo" class="menu-box menu">
      <li v-for="(obj, key) in uccnInfo.menuDtoList" :key="key">
        <a v-if="id" :href="`/${uccnInfo.websiteUrl}/home?id=${id}#${obj.moduleId}`" @click="() => $emit('closeNav')"><i :class="MenuIcon[key] || 'h5pp h5pp-icon-org'"></i>{{ obj.name }}</a>
        <a v-else-if="obj.linkType === 1" :href="obj.linkUrl | TOLINK" target="_blank"><i :class="MenuIcon[key] || 'h5pp h5pp-icon-org'"></i>{{ obj.name }}</a>
        <a v-else :href="`/${uccnInfo.websiteUrl}/home#${obj.moduleId}`" @click="() => $emit('closeNav')"><i :class="MenuIcon[key] || 'h5pp h5pp-icon-org'"></i>{{ obj.name }}</a>
      </li>
    </ul>
    <ul v-if="uccnInfo.type" class="menu-box record">
      <li v-for="(item, key) in AppendMenuObj['neitui']" :key="key">
        <a href="javascript:;" @click="onChangePage(item)" class="clearfix"><i :class="item.icon"></i>{{ item.name }}</a>
      </li>
    </ul>
    <!-- 是否为校园大使 -->
    <ul v-if="user && user.externalFlag === 2" class="menu-box mine">
      <li v-for="(item, key) in AppendMenuObj['ambassador']" :key="key">
        <a href="javascript:;" @click="onChangePage(item)" class="clearfix"><i :class="item.icon" />{{ item.name }}</a>
      </li>
      <li v-if="user && user.vipToken"><a href="javascript:;" @click="onSignOut"><i class="h5pp h5pp-icon-sign-out2" />退出登录</a></li>
    </ul>
    <ul v-else class="menu-box mine">
      <li v-for="(item, key) in AppendMenuObj['default']" :key="key">
        <a href="javascript:;" @click="onChangePage(item)" class="clearfix"><i :class="item.icon" />{{ item.name }}</a>
      </li>
      <li v-if="!mobile"><a href="javascript:;" @click="onChangePage({'loginType': 3})" class="clearfix"><i class="h5pp h5pp-icon-phone2" />绑定手机</a></li>
      <li v-if="!email"><a href="javascript:;" @click="onChangePage({'loginType': 4})" class="clearfix"><i class="h5pp h5pp-icon-email2" />绑定邮箱</a></li>
      <li v-if="user && user.vipToken"><a href="javascript:;" @click="onSignOut"><i class="h5pp h5pp-icon-sign-out2" />退出登录</a></li>
    </ul>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { local } from '@/utils'

  const AppendMenuObj = {
    neitui: [
      { name: '内推/奖励记录', icon: 'h5pp h5pp-icon-record', pathName: 'recommend' },
      { name: '认证内推人', icon: 'h5pp h5pp-icon-identity-user', pathName: '' },
      { name: '个人中心', icon: 'h5pp h5pp-icon-name', pathName: 'userPersonal' },
    ],
    ambassador: [
      { name: '推荐记录', icon: 'h5pp h5pp-icon-record', pathName: 'recordNominate' },
      { name: '个人中心', icon: 'h5pp h5pp-icon-name', pathName: 'userPersonal' },
    ],
    default: [
      { name: '我的简历', icon: 'h5pp h5pp-icon-file2', pathName: 'myResume' },
      { name: '投递进展', icon: 'h5pp h5pp-icon-calendar', pathName: 'delivery' }
    ]
  };
  
  const MenuIcon = {
    0: 'h5pp h5pp-icon-org',
    1: 'h5pp h5pp-icon-job2',
    2: 'h5pp h5pp-icon-abouts',
  }
  export default {
    inject: {
      pageFrom: {
        default: ''
      },
      jobId: {
        default: ''
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      id() {
        return this.$route.query.id; 
      },
      user() {
        return JSON.parse(localStorage.getItem('user')|| '{}');
      },
      mobile() {
        return (JSON.parse(local.get('user') || '{}')).mobile || '';
      },
      email() {
        return (JSON.parse(local.get('user') || '{}')).email || '';
      },
      pathName() {
        return this.$route.name;
      }
    },
    data() {
      return {
        AppendMenuObj,
        MenuIcon
      }
    },
    created() {
      if(this.user.personId) {
        if(this.user.externalFlag) {
          AppendMenuObj['neitui'][1].pathName = 'identityEmployee';
          Object.assign(AppendMenuObj['neitui'][1], {
            query: { pageFrom: this.pageFrom, jobId: this.jobId}
          });
        } else {
          AppendMenuObj['neitui'][1].pathName = 'userPersonal';
          delete AppendMenuObj['neitui'][1].pageFrom;
        }
      } else {
        AppendMenuObj['neitui'][1].pathName = 'identity';
        Object.assign(AppendMenuObj['neitui'][1], {
          query: { pageFrom: this.pageFrom, jobId: this.jobId }
        });
      }
    },
    methods: {
      onChangePage(obj={}) {
        if(!Object.keys(this.user).length || (this.user && !this.user.mobile && !this.user.email) || obj.loginType) {
          return this.$router.push({ name: 'login', query: { pageFrom: this.pageFrom, jobId: this.jobId, loginType: obj.loginType || undefined }});
        }
        if(obj.pathName === this.pathName) {
          this.$emit('closeNav');
        } else {
          this.$router.push({
            name: obj.pathName,
            query: {
              websiteId: this.uccnInfo.id,
              ...obj.query
            }
          });
        }
      },
      onSignOut() {
        this.$http.get('/api/internal/recom/person/login/out.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.$store.commit('CLEAR_USER_INFO');
            this.$emit('closeNav',this.uccnInfo.id);
          }
        });
      }
    }
  }
</script>