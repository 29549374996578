<template>
  <div class="head-navs-box" :style="{'background-color': NavBgColor[navBarBgColor]}" :class="{'head-navs-hyaline': navBarBgColor === 13 && !isScroll, 'head-navs-hyaline-scroll': navBarBgColor === 13 && isScroll, 'head-navs-color': [1,2,3,11,6,7,8,9].includes(navBarBgColor), 'yongfu': [1029, 1027].includes(uccnInfo.id)}">
    <div v-if="uccnInfo" class="head-navs clearfix">
      <div v-if="[1029, 1027].includes(uccnInfo.id)" class="img-logo yongfu">
        <img v-if="navBarBgColor === 13 && !isScroll && $route.name === 'Home'" src="https://hy-awa-ats.oss-cn-hangzhou.aliyuncs.com/website/de5f9c09bd23d8712e945fc5e9d0faa3.png">
        <img v-else src="https://hy-awa-ats.oss-cn-hangzhou.aliyuncs.com/website/ecad6e9a91fb7dfdf49f30c0683b2150.png">
      </div>
      <div v-else class="img-logo">
        <img v-if="uccnInfo.logoUrl" :src="uccnInfo.logoUrl">
      </div>
      <ul class="menu-list">
        <li v-for="(obj, key) in uccnInfo.menuDtoList" :key="key" class="el-sl" :class="{active: activeId === `#${obj.id}`}">
          <a v-if="id" :href="`/${uccnInfo.websiteUrl}/home?id=${id}#${obj.moduleId}`" @click="goAnchor(`#${obj.id}`)">{{ obj.name }}</a>
          <a v-else-if="obj.linkType === 1" :href="obj.linkUrl | TOLINK" target="_blank">{{ obj.name }}</a>
          <a v-else href="javascript:;" @click="goAnchor(`#${obj.id}`, obj.moduleId)">{{ obj.name }}</a>
        </li>
      </ul>
      <van-field v-if="searchBoxShowType === 2" class="input-search" :class="{'input-search-show': isSearch}" v-model.trim="keyWord" placeholder="搜索职位关键字">
        <template #button>
          <van-icon name="search" @click="onSearch" />
        </template>
      </van-field>
      <el-dropdown :show-timeout="0" :hide-timeout="0" class="pc-dropdown-menu">
        <span class="el-dropdown-link" @click="onLogin">
          <span class="username el-sl">{{ recommendPeople.name || mobile || email || '请登录' }}</span>
          <i v-if="isLogin" class="h5pp h5pp-icon-arrow-down-entity"></i>
        </span>
        <el-dropdown-menu v-if="mobile || email" slot="dropdown" class="home-dropdown-menu">
          <el-dropdown-item :disabled="true">
            <div v-if="personId && recommendPeople.name" class="info-box">
              <i class="h5pp h5pp-icon-head"></i>
              <span class="username el-sl">{{ recommendPeople.name }}</span>
              <span class="role">{{ {0: '企业员工', 1: '外部员工', 2: '校园大使'}[recommendPeople.externalFlag] }}</span>
            </div>
            <div class="contact">
              <div v-if="mobile" class="mobile-email">{{ mobile }}</div>
              <div v-if="email" class="mobile-email">{{ email }}</div>
            </div>
          </el-dropdown-item>
          <template v-if="isRecommendWebsite">
            <el-dropdown-item><router-link :to="{name: 'recommend'}"><i class="h5pp h5pp-icon-center-calendar" />推荐数据</router-link></el-dropdown-item>
            <el-dropdown-item><router-link :to="{name: 'userPersonal'}"><i class="h5pp h5pp-icon-head" />个人中心</router-link></el-dropdown-item>
          </template>
          <template v-if="recommendPeople.externalFlag === 2">
            <el-dropdown-item><router-link :to="{name: 'recordNominate'}"><i class="h5pp h5pp-icon-record" />推荐记录</router-link></el-dropdown-item>
            <el-dropdown-item><router-link :to="{name: 'userPersonal'}"><i class="h5pp h5pp-icon-head" />个人中心</router-link></el-dropdown-item>
          </template>
          <template v-else>
            <el-dropdown-item><router-link :to="{name: 'myResume', query: { websiteId: uccnInfo.id }}"><i class="h5pp h5pp-icon-file" />我的简历</router-link></el-dropdown-item>
            <el-dropdown-item><router-link :to="{name: 'delivery'}"><i class="h5pp h5pp-icon-works" />投递进展</router-link></el-dropdown-item>
          </template>
          <el-dropdown-item v-if="!email"><a href="javascript:;" @click="bindPhoneEmail(5)"><i class="h5pp h5pp-icon-email" />绑定邮箱</a></el-dropdown-item>
          <el-dropdown-item v-if="!mobile"><a href="javascript:;" @click="bindPhoneEmail(3)"><i class="h5pp h5pp-icon-phone" />绑定手机</a></el-dropdown-item>
          <el-dropdown-item><a href="javascript:;" @click="logout"><i class="h5pp h5pp-icon-sign-out2" />退出登录</a></el-dropdown-item>
        </el-dropdown-menu>
        <el-dropdown-menu v-else class="empty-dropdown-menu" />
      </el-dropdown>
    </div>
    <popup-login-process :loginObj="loginObj"></popup-login-process>
  </div>
</template>

<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
  import { Button, Field, Icon } from 'vant'
  import { local } from '@/utils'
  import { NavBgColor } from '@/enums/EnumBase'
  import popupLoginProcess from '@/components/pc/dialog/loginProcess'

  export default {
    components: {
      [Dropdown.name]: Dropdown,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
      [Button.name]: Button,
      [Field.name]: Field,
      [Icon.name]: Icon,
      popupLoginProcess
    },
    props: {
      activePage: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        NavBgColor,
        activeId: '',
        loginObj: {
          isShow: false
        },
        identityObj: {
          isShow: false
        },
        isLogin: true,
        recommendPeople: {},
        bindType: 1, //绑定手机或邮箱
        isScroll: false,
        keyWord:'',
        isSearch: false
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      id() {
        return this.$route.query.id; 
      },
      mobile() {
        return (JSON.parse(local.get('user') || '{}')).mobile || '';
      },
      email() {
        return (JSON.parse(local.get('user') || '{}')).email || '';
      },
      personId() {
        return (JSON.parse(local.get('user') || '{}')).personId || '';
      },
      isRecommendWebsite() {
        return (JSON.parse(local.get('uccnInfo') || '{}')).type || '';
      },
      navBarBgColor() {
        if(this.uccnInfo && this.uccnInfo.moduleDtoList && this.uccnInfo.moduleDtoList.length && this.uccnInfo.moduleDtoList.find(item => item.type===1) && this.uccnInfo.moduleDtoList.find(item => item.type===1).moduleContentList && this.uccnInfo.moduleDtoList.find(item => item.type===1).moduleContentList.length) {
          return this.uccnInfo.moduleDtoList.find(item => item.type===1).moduleContentList[0]?.navBarBgColor;
        }
      },
      searchBoxShowType() {
        if(this.uccnInfo && this.uccnInfo.moduleDtoList && this.uccnInfo.moduleDtoList.length && this.uccnInfo.moduleDtoList.find(item => item.type===1) && this.uccnInfo.moduleDtoList.find(item => item.type===1).moduleContentList && this.uccnInfo.moduleDtoList.find(item => item.type===1).moduleContentList.length) {
          return this.uccnInfo.moduleDtoList.find(item => item.type===1)?.moduleContentList[0]?.searchBoxShowType;
        }
      }
    },
    created() {
      this.init();
    },
    mounted() {
      if(this.navBarBgColor === 13) {
        window.addEventListener('scroll', this.handleScroll); // 监听页面滚动
      }
    },
    beforeDestroy () {
      if(this.handleScroll) {
        window.removeEventListener('scroll', this.handleScroll);
      }
    },
    methods: {
      init() {
        if(this.uccnInfo) {
          window.document.title = this.uccnInfo.websiteTitle;
        }
        let hashId = this.$route.hash || this.activePage;
        if(this.$route.name === 'Home') {
          hashId = `#${this.uccnInfo.menuDtoList[0].id}`;
        }
        this.goAnchor(hashId);
        if(this.personId) {
          this.queryUserInfo();
        }
      },
      // 获取页面滚动距离
	    handleScroll () {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        this.isScroll = !!scrollTop;
	    },
      onSearch() {
        if(this.isSearch) {
          this.$emit('onSearch', this.keyWord);
        }
        this.isSearch = true;
        // this.isSearch = !this.isSearch;
      },
      queryUserInfo() {
        this.$http.get('/api/internal/recom/person/info.json', {params: {
          personId: this.personId
        } }).then(data => {
          if(data) {
            this.recommendPeople = data;
          }
        });
      },
      goAnchor(index, id) {
        this.activeId = index;
        if(id && this.$route.name != 'Home') {
          location.href = `/${this.uccnInfo.websiteUrl}/home#${id}`
        }
        if(id && this.$route.name == 'Home' && document.getElementById(id)) {
          document.getElementById(id).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      },
      onSelectRole(flag) {
        if(flag) {
          this.employeeObj.isShow = true;
        } else {
          this.outUserObj.isShow = true;
        }
      },
      bindPhoneEmail(type) {
        Object.assign(this.loginObj, {
          type: type,
          isShow: true
        })
      },
      onLogin() {
        if(!this.mobile && !this.email) {
          this.loginObj.isShow = true;
        }
      },
      logout() {
        this.$http.get('/api/internal/recom/person/login/out.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.$store.commit('CLEAR_USER_INFO');
            if(this.$route.name !== 'Home') {
              this.$router.push({
                name: 'Home',
                query: { id: this.uccnInfo.id },
                params:  { pathMatch: local.get('rootPath') || '' }
              });
            } else {
              location.reload();
            }
          }
        });
      }
    }
  }
</script>