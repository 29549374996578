<template>
  <el-dialog title="外部用户" :visible.sync="dialogData.isShow" :close-on-click-modal="false" class="out-user-modal">
    <el-form :model="outUserForm" ref="outUserForm">
      <el-form-item
        prop="name"
        :rules="[{required: true, message: '请输入姓名', trigger: 'blur' }]">
        <el-input v-model.trim="outUserForm.name" placeholder="姓名" :maxlength="20"><i slot="prefix" class="h5pp h5pp-icon-name"></i></el-input>
      </el-form-item>
    </el-form>
    <a href="javascript:;" slot="footer" @click="onRegistory" class="dialog-footer">确认</a>
  </el-dialog>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Dialog, Form, FormItem, Input, Button, Message } from 'element-ui'

  export default {
    props: {
      dialogData: Object
    },
    components: {
      [Dialog.name]: Dialog,
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Button.name]: Button,
      [Message.name]: Message
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    data() {
      return {
        isBtnLoading: false,
        outUserForm: {
          name: ''
        }
      }
    },
    methods: {
      onRegistory() {
        this.$refs.outUserForm.validate((valid) => {
          if(!valid) {
            return;
          }
          this.isBtnLoading = true;
          this.$http.get('/api/internal/recom/person/auth.json', {params: {
            authType: 1, // 0 公司内部认证 1 外部员工认证
            name: this.outUserForm.name,
            websiteId: this.uccnInfo.id
          }}).then((data) => {
            if(data) {
              Message.success('认证成功');
              this.$store.commit('SET_USER_INFO', data);
              location.reload();
              this.dialogData.isShow = false;
            }
          }).catch(err => {
            console.log(err);
          }).finally(() => {
            this.isBtnLoading = false;
          });
        });
      }
    }
  }
</script>
