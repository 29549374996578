<template>
  <div>
    <el-dialog title="请选择身份" :visible.sync="dialogData.isShow" :close-on-click-modal="false" class="pc-identity-modal">
      <div class="pc-identity-wrap">
        <div class="company-employee" @click="onRegistory(1)">
          <i class="h5pp h5pp-icon-company"></i>
          <dl class="user-box">
            <dt>我是企业员工</dt>
            <dd>EMPLOYEES OF THE ENTERPRISES</dd>
          </dl>
          <i class="h5pp h5pp-icon-arrows-right"></i>
        </div>
        <div v-if="authInfoObj.externalRecomFlag" class="out-user" @click="onRegistory()">
          <i class="h5pp h5pp-icon-user"></i>
          <dl class="user-box">
            <dt>我是外部用户</dt>
            <dd>EXTERNAL USER</dd>
          </dl>
          <i class="h5pp h5pp-icon-arrows-right"></i>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Dialog } from 'element-ui'

  export default {
    props: {
      dialogData: Object
    },
    components: {
      [Dialog.name]: Dialog
    },
    data() {
      return {
         authInfoObj: {}
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      this.queryConfig();
    },
    methods: {
      queryConfig() {
        this.$http.get('/api/internal/recom/auth/info.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.authInfoObj = data;
          }
        })
      },
      onRegistory(flag) {
        this.$emit('onSelectRole', flag);
        this.dialogData.isShow = false;
      }
    }
  }
</script>
