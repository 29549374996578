<template>
  <el-dialog title="企业员工认证" :visible.sync="dialogData.isShow" :close-on-click-modal="false" class="employee-modal">
    <el-form :model="employeeForm" ref="employeeForms">
      <el-form-item
        prop="name"
        :rules="[{required: true, message: '请输入姓名', trigger: 'blur' }]">
        <el-input v-model.trim="employeeForm.name" placeholder="姓名" :maxlength="20"><i slot="prefix" class="h5pp h5pp-icon-name"></i></el-input>
      </el-form-item>
      <el-form-item
        v-if="authInfoObj.mobileSwitchFlag"
        prop="authCode"
        :rules="[{ required: authInfoObj.mobileSwitchFlag, message: '请输入员工邀请码', trigger: 'blur' }]">
        <el-input v-model.trim="employeeForm.authCode" placeholder="员工邀请码"><i slot="prefix" class="h5pp h5pp-icon-code"></i></el-input>
      </el-form-item>
      <el-form-item
        v-if="authInfoObj.emailSwitchFlag"
        prop="email"
         :rules="[{ required: authInfoObj.emailSwitchFlag, message: '请输入企业邮箱', trigger: 'blur' },
        { validator: emailValidator, trigger: 'blur' },
        { validator: emailValidatorDomain, trigger: 'blur' }]">
        <el-input v-model.trim="employeeForm.email" placeholder="企业邮箱">
          <i slot="prefix" class="h5pp h5pp-icon-email2"></i>
        </el-input>
      </el-form-item>
    </el-form>
    <el-button slot="footer" @click="onRegistory" :loading="isBtnLoading" class="dialog-footer">
      身份认证
    </el-button>
  </el-dialog>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Dialog, Form, FormItem, Input, Button, Message } from 'element-ui'

  export default {
    props: {
      dialogData: Object
    },
    components: {
      [Dialog.name]: Dialog,
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Button.name]: Button,
      [Message.name]: Message
    },
    data() {
      return {
        isBtnLoading: false,
        employeeForm: {
          name: '',
          authCode: '',
          email: ''
        },
        authInfoObj: {}
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      this.queryConfig();
    },
    methods: {
      queryConfig() {
        this.$http.get('/api/internal/recom/auth/info.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.authInfoObj = data;
          }
        })
      },
      emailValidator(rule, val, callback) {
        if(!val || !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(val)) {
          callback(new Error('请输入正确的邮箱'));
        } else {
          callback();
        }
      },
      emailValidatorDomain(rule, val, callback) {
        if(this.authInfoObj.domains && this.authInfoObj.domains.length) {
          let domainIndex = val.lastIndexOf('@');
          let domain = val.substring(domainIndex+1, val.length);
          let emailDomains = this.authInfoObj.domains.map(item => item.emailDomain);
          if(!emailDomains.includes(domain)) {
            callback(new Error('此邮箱不为企业内部邮箱'))
          } else {
            callback();
          }
        } else {
          callback();
        }
      },
      onRefresh() {
        this.$http.get('/api/internal/recom/person/flush.json', {params: {
          websiteId: this.uccnInfo.id
        } }).then(data => {
          if(data.personId) {
            this.$store.commit('SET_USER_INFO', data);
            if(data.checkFlag) {
              this.$emit('successIdentityNews');
              this.dialogData.isShow = false;
            } else {
              location.reload();
            }
          }
        });
      },
      onRegistory() {
        this.$refs.employeeForms.validate(valid => {
          if(!valid) {
            return;
          }
          this.isBtnLoading = true;
          this.$http.get('/api/internal/recom/person/auth.json', {params: {
            authType: 0, // 0 公司内部认证 1 外部员工认证
            name: this.employeeForm.name,
            authCode: this.employeeForm.authCode,
            email: this.employeeForm.email,
            websiteId: this.uccnInfo.id
          }}).then((data) => {
            if(this.authInfoObj.emailSwitchFlag) {
              this.$emit('successEmail');
              this.dialogData.isShow = false;
            } else {
              Message.success('认证成功');
              this.onRefresh();
            }
          }).finally(() => {
            this.isBtnLoading = false;
          });
        });
      }
    }
  }
</script>
