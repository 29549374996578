<template>
  <el-dialog title="校园大使身份认证" :visible.sync="dialogData.isShow" :close-on-click-modal="false" class="dialog-auth-ambassador">
    <el-form :model="userForm" ref="userForm" class="user-form">
      <template v-if="type === 1">
        <el-form-item prop="name" clearable :rules="[{required: true, message: '请输入用户名'}]">
          <el-input v-model.trim="userForm.name" placeholder="请输入"><i slot="prefix" class="h5pp h5pp-icon-name" /></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="userForm.email" disabled><i slot="prefix" class="h5pp h5pp-icon-email2" /></el-input>
        </el-form-item>
        <el-form-item prop="mobile">
          <el-input v-model="userForm.mobile" disabled>
            <i slot="prefix" class="h5pp h5pp-icon-phone2" />
            <a href="javascript:;" class="field-link" @click="type = 2" slot="suffix">修改手机号</a>
          </el-input>
        </el-form-item>
        <el-form-item prop="schoolName">
          <el-input v-model="userForm.schoolName" disabled><i slot="prefix" class="h5pp h5pp-icon-education1" /></el-input>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item prop="mobile" clearable :rules="[{required: true, message: '请输入手机号'},{ pattern: /^1\d{10}$/, message: '请输入正确的手机号', trigger: 'blur'}]">
          <el-input v-model.trim="userForm.mobile" placeholder="输入手机号" :maxlength="11"><i slot="prefix" class="h5pp h5pp-icon-phone2" /></el-input>
        </el-form-item>
        <el-form-item prop="verifyCode" :rules="[{ required: true, message: '请输入验证码' }]" clearable>
          <el-input v-model.trim="userForm.verifyCode" placeholder="短信验证码">
            <i slot="prefix" class="h5pp h5pp-icon-code"></i>
            <a href="javascript:;" @click="sendVerifyCode" slot="suffix">
              <template v-if="operateType === 2">
                <span class="second">{{ secondsCount }}s 后重新发送</span>
              </template>
              <template v-else>
                {{ operateType === 1 ? '发送验证码' : '重新发送' }}  
              </template>
            </a>
          </el-input>
        </el-form-item>
      </template>
    </el-form>
    <a href="javascript:;" slot="footer" @click="onSubmit" :loading="isBtnLoading" class="dialog-footer">身份认证</a>
  </el-dialog>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Dialog, Form, FormItem, Input, Button, Message } from 'element-ui'

  export default {
    props: {
      dialogData: Object
    },
    components: {
      [Dialog.name]: Dialog,
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Button.name]: Button
    },
    data() {
      return {
        isBtnLoading: false,
        type: 1, // 1身份认证；2获取验证码
        a: '',
        b: '',
        sign: '',
        userForm: {
          name: '',
          email: '',
          schoolName: '',
          mobile: '',
          verifyCode: ''
        },
        operateType: 1,
        secondsCount: 60
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      this.a = this.$route.query.a || '';
      this.b = this.$route.query.b || '';
      this.sign = this.$route.query.sign || '';
      this.queryData();
    },
    methods: {
      queryData() {
        this.$http.get('/api/school/ambassador/auth/info.json', {params: {
          websiteId: this.uccnInfo.id,
          a: this.a,
          b: this.b,
          sign: this.sign
        }}).then(data => {
          if(data) {
            this.userForm = data;
          }
        });
      },
      cycleCallTime() {
        let timer = setTimeout(() => {
          if(this.secondsCount <= 0) {
            this.operateType = 3;
            this.secondsCount = 60;
            clearTimeout(timer);
            return;
          }
          this.secondsCount -= 1;
          this.cycleCallTime();
        }, 1e3);
      },
      sendVerifyCode() {
        if(this.operateType === 2) {
          return;
        }
        this.$refs.userForm.validateField('mobile', (errMsg => {
          if(errMsg) {
            return;
          }
          this.$http.get('/api/auth/verify/code/send.json', {params: {
            mobile: this.userForm.mobile,
            sendType: 6
          }});
          this.operateType = 2;
          this.cycleCallTime();
        }));
      },
      onSubmit() {
        this.$refs.userForm.validate(valid => {
          if(!valid) {
            return;
          }
          this.isBtnLoading = true;
          if(this.type === 2) {
            this.$http.post('/api/school/ambassador/auth/update/mobile.json', {
              mobile: this.userForm.mobile,
              verifyCode: this.userForm.verifyCode,
              a: this.a,
              b: this.b,
              sign: this.sign
            }).then(data => {
              if(data.errCode) {
                Message.error(data.errMessage);
              } else {
                this.type = 1;
              }
              this.isBtnLoading = false;
            });
          } else {
            this.$http.post('/api/school/ambassador/auth/activation.json', {
              a: this.a,
              b: this.b,
              sign: this.sign,
              name: this.userForm.name
            }).then(data => {
              if(data) {
                this.$store.commit('SET_USER_INFO', {
                  name: data.name,
                  mobile: data.mobile,
                  personId: data.personId,
                  companyId: data.companyId,
                  externalFlag: data.externalFlag,
                  type: data.type
                });
                this.$emit('success');
                this.dialogData.isShow = false;
                this.$router.push({
                  name: 'userPersonal'
                });
              }
              this.isBtnLoading = false;
            });
          }
        });
      }
    }
  }
</script>
