<template>
  <el-dialog title="信息完善" :visible.sync="dialogData.isShow" :close-on-click-modal="false" class="news-modal" @close="onClose">
    <el-form :model="newsForm" ref="newsForm">
      <el-form-item
        v-if="authInfoObj.registerInfo.email"
        prop="email"
        :rules="[{ required: authInfoObj.registerInfo.emailRequired, message: '请输入邮箱', trigger: 'blur' },
        { validator: emailValidator, message: '请输入正确的邮箱', trigger: 'blur'}]">
        <el-input v-model.trim="newsForm.email" placeholder="企业邮箱">
          <i slot="prefix" class="h5pp h5pp-icon-email2"></i>
        </el-input>
      </el-form-item>
      <el-form-item
        v-if="authInfoObj.registerInfo.workNo"
        prop="workNo"
        :rules="[{ required: authInfoObj.registerInfo.workNoRequired, message: '请输入工号', trigger: 'blur' }]">
        <el-input v-model.trim="newsForm.workNo" placeholder="工号"><i slot="prefix" class="h5pp h5pp-icon-work-number"></i></el-input>
      </el-form-item>
      <el-form-item
        v-show="authInfoObj.registerInfo.orgNo"
        prop="orgId"
        :rules="[{ required: authInfoObj.registerInfo.orgNoRequired, message: '请选择部门', trigger: 'blur' }]">
        <sub-select-org v-model="newsForm.orgId" :id="uccnInfo.id" />
      </el-form-item>
    </el-form>
    <a href="javascript:;" slot="footer" @click="onRegistory" class="dialog-footer">
      身份认证
    </a>
  </el-dialog>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Dialog, Form, FormItem, Input, Button, Select, Tree, Message } from 'element-ui'
  import subSelectOrg from '@/components/pc/selectOrg'

  export default {
    props: {
      dialogData: Object
    },
    components: {
      [Dialog.name]: Dialog,
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Button.name]: Button,
      [Select.name]: Select,
      [Tree.name]: Tree,
      [Message.name]: Message,
      subSelectOrg
    },
    data() {
      return {
        deptData: [],
        isBtnLoading: false,
        newsForm: {
          email: '',
          number: '',
          orgId: '',
          orgName: '',
        },
        authInfoObj: {
          registerInfo: {}
        }
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      this.queryConfig();
    },
    methods: {
      queryConfig() {
        this.$http.get('/api/internal/recom/auth/info.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            this.authInfoObj = data;
          }
        })
      },
      emailValidator(rule, val, callback) {
        if(!val || !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(val)) {
          callback(new Error('请输入正确的邮箱'));
        } else {
          callback();
        }
      },
      onRegistory() {
        this.$refs.newsForm.validate((valid) => {
          if(!valid) {
            return;
          }
          this.isBtnLoading = true;
          this.$http.get('/api/internal/recom/person/info/save.json', {params: {
            workNo: this.newsForm.workNo,
            email: this.newsForm.email,
            orgId: this.newsForm.orgId,
            websiteId: this.uccnInfo.id
          }}).then(() => {
            Message.success('操作成功');
            location.reload();
            this.$emit('success');
          }).catch(err => {
          }).finally(() => {
            this.isBtnLoading = false;
          });
        });
      },
      onClose() {
        location.reload();
      }
    },
    watch: {
      'newsForm.orgId'(val) {
        if(val) {
          this.$refs.newsForm.clearValidate(['orgId']);
        }
      }
    }
  }
</script>
