<template>
    <el-dialog :visible.sync="dialogData.isShow" :close-on-click-modal="false" class="login-modal" :class="{'protocol-dialog': isProtocol}">
      <div slot="title" class="login-head">
        <i v-if="[4].includes(type) || isProtocol" class="h5pp h5pp-icon-arrows-left" @click="back"></i>
        <ul class="nav-tab">
          <li v-if="isProtocol" class="active">聘聘云隐私协议</li>
          <li v-else-if="[3,4,5].includes(type)" class="active">{{title}}</li>
          <li v-else v-for="item in tabNav" :class="{'active': item.type === type}" @click="handleEmailLogin(item.type)">{{item.name}}</li>
        </ul>
      </div>
      
      <el-form v-if="!isProtocol" :model="loginForm" ref="loginForm">
        <el-form-item
          v-if="type === 1 || type === 3"
          prop="mobile"
          clearable
          :rules="[{required: true, message: '请输入手机号'}, { pattern: /^1\d{10}$/, message: '请输入正确的手机号', trigger: 'blur'}]">
          <el-input v-model.trim="loginForm.mobile" placeholder="手机号码" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item
          v-if="type === 1 || type === 3"
          prop="verifyCode"
          :rules="[{ required: true, message: '请输入验证码' }]"
          clearable
          class="verify-code"
          >
          <el-input v-model.trim="loginForm.verifyCode" placeholder="验证码"></el-input>
          <a href="javascript:;" @click="onSendCode(1)" :class="{'send-code': secondFlag}">
            <template v-if="operateType === 2">
              <span class="second">{{ secondsCount }} s 后重新发送</span>
            </template>
            <template v-else>
              {{ operateType === 1 ? '发送验证码' : '重新发送' }}
            </template>
          </a>
        </el-form-item>
        <!-- 邮箱登录 -->
        <el-form-item
          v-if="type === 2 || type === 4 || type === 5"
          prop="email"
          clearable
          :rules="[{required: true, message: '请输入邮箱'}, {pattern: /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_\-\.]+\.([a-zA-Z]{2,4})$/, message: '邮箱格式不正确', trigger: 'blur'}]">
          <el-input v-model.trim="loginForm.email" auto-complete="new-password" placeholder="输入邮箱" :maxlength="50"></el-input>
        </el-form-item>
        <el-form-item
          v-if="type === 2 || type === 4 || type === 5"
          prop="password"
          clearable
          class="email-password"
          :rules="[{required: true, message: '请输入密码'}]">
          <el-input 
            v-model.trim="loginForm.password" 
            placeholder="密码" 
            :maxlength="10" 
            show-password
            auto-complete="new-password"
          >
          </el-input>
          <div v-if="type === 2" class="option-btn"><span @click="handleEmailLogin(4)">邮箱注册 / 忘记密码</span></div>
        </el-form-item>
        <el-form-item
          v-if="type === 4"
          prop="verifyCodeEmail"
          class="verify-code"
          :rules="[{ required: true, message: '请输入验证码' }]"
          clearable>
          <el-input v-model.trim="loginForm.verifyCodeEmail" placeholder="邮箱验证码"></el-input>
          <a href="javascript:;" @click="onSendCode(2)" :class="{'send-code': secondEmailFlag}">
            <template v-if="operateType === 2">
              <span class="second">{{ secondsCount }} s 后重新发送</span>
            </template>
            <template v-else>
              {{ operateType === 1 ? '发送验证码' : '重新发送' }}
            </template>
          </a>
        </el-form-item>
        <el-form-item
          v-if="type === 5"
          prop="verifyCode"
          class="verify-code"
          :rules="[{ required: true, message: '请输入验证码' }]"
          clearable>
          <el-input v-model.trim="loginForm.verifyCode" placeholder="邮箱验证码"></el-input>
          <a href="javascript:;" @click="onSendCode(2)" :class="{'send-code': secondEmailFlag}">
            <template v-if="operateType === 2">
              <span class="second">{{ secondsCount }} s 后重新发送</span>
            </template>
            <template v-else>
              {{ operateType === 1 ? '发送验证码' : '重新发送' }}
            </template>
          </a>
        </el-form-item>
      </el-form>
      <div v-else class="protocol-box">
        <div class="article-content">
          <h4>隐私声明</h4>
          <p class="article-item">
            聘聘云尊重并保护所有使用聘聘云的用户的个人隐私及个人信息。为了给用户提供更有针对性、更具个人化的服务，聘聘云会按照《个人信息保护法》及本网站的规定使用、处理及披露用户的个人信息，但聘聘云将以高度的勤勉、审慎义务对待这些信息。本网站会不时更新本隐私声明。
            <span class="strong">
              用户浏览阅读本网站服务协议并点击同意后，即视为用户已经同意本隐私权政策全部内容，尤其是同意授权我们对用户信息的处理，用户信息的处理包括个人信息的处理包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等，但我们在信息处理时将会严格遵循法律要求和规定。您也可选择拒绝同意，如您拒绝提供相应信息或拒绝授权，请不要点击下一步，拒绝后我们无法正常为您提供服务。
            </span>
            本隐私权政策属于本网站服务协议不可分割的一部分。若我们对隐私政策做出变更或调整，我们将会在本网站上更新并发布修订后的最新政策。新的政策在不违反法律规定的情况下将于发布时生效。
          </p>
          <p class="article-item">
            <span class="strong">用户的隐私信息：</span>用户隐私信息是指属于用户私有的，不适于向他人公开或披露的信息。除非用户选择公开或出于法律要求或本隐私权政策规定之情形，本网站不会公开或向第三方披露用户的隐私信息。用户在使用本网站时可能为本网站所记录的隐私信息有：
          </p>
          <p class="article-item">
            <span class="strong">注册信息：</span>在用户注册或登录本网站的账户时，用户向本网站提供的个人注册信息。个人用户的注册信息包括但不限于用户的姓名、手机号码、用户名、所在企业、职位、电子邮件等等；企业用户的注册信息包括但不限于用户的邮箱、真实公司全称、公司网站、行业领域、发展阶段、联系人姓名、职位、联系方式等。
            <br/>下单：用户在聘聘云官网下单进行背调时，需根据页面提示所需信息，包括候选人姓名、电话号码、邮箱、候选人工作经历以及其他必需信息。
          </p>
          <p class="article-item no-mt">
            <span class="strong">浏览信息：</span>在用户使用本网站服务，或访问本网站网页时，本网站可能会自动接收并记录的用户的浏览器和计算机上的信息，包括但不限于浏览器的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及用户需求的网页记录等数据。</br>
            <span class="strong">个人信息：</span>本网站通过合法途径从商业伙伴处取得的用户个人数据。
          </p>
          <h4 class="mt20">用户隐私信息的保护规则</h4>
          <h4>信息使用</h4>
          <p class="article-item no-mt">我们将按照前述声明在服务中使用您或候选人的个人信息，如我们基于本政策未载明其他用途或非特定目的收集或使用您及候选人的其他个人信息，我们会事先征求您的同意。</br>我们将根据您的授权，在为您提供服务时使用上述信息。若您希望撤销授权，可联系我们撤销相关授权，我们将及时停止信息使用并根据您的要求对您的个人信息进行删除。</br>
                1）本网站不会向任何无关第三方提供、出售、出租、分享或交易用户的隐私信息，除非事先得到用户的许可，或该第三方和本网站单独或共同为用户提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
            <br/>2）本网站亦不允许任何第三方以任何手段在聘聘云内收集、编辑、出售或者无偿传播用户的隐私信息。任何聘聘云用户如从事上述活动，一经发现，本网站有权立即作出包括终止与该用户的服务协议在内的相关举措。
            <br/>3）为服务用户的目的，本网站可能通过使用用户的隐私信息，向用户提供用户可能感兴趣的信息，包括但不限于向用户发出产品和服务信息，或通过系统向用户展示个性化的第三方推广信息，或者与本网站合作伙伴共享信息以便他们向用户发送有关其产品和服务的信息（后者需要用户的事先同意）。
            <br/>4）本网站可通过对用户的隐私信息脱敏并进行各种不同维度的统计，并对统计所得之数据（但仅限于统计数据）不受限制地进行使用和处分。
            <br/>5）本网站可以使用用户的隐私信息以预防、发现、调查欺诈、非法、侵权或违反与本网站协议、政策或规则的行为，以保护用户、其他本网站用户，或本网站合法权益。
                在如下情况下，本网站将依据用户的自身意愿或法律的规定全部或部分披露用户的隐私信息：
            <br/>1）经用户事先同意，向第三方披露。
            <br/>2）根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露。
            <br/>3）如用户出现违反中国有关法律、法规，和/或违反在本网站上创建的与其他用户之间的合同规定，和/或侵犯他人合法权益或者违反本网站服务协议或相关规则的情况，需要向第三方披露。
            <br/>4）为提供用户所要求的产品和服务，而必须和第三方分享用户的个人信息。
            <br/>5）其它本网站根据法律、法规或者网站政策认为合适的披露。
            <br/>用户了解并同意，以下信息不属于用户的隐私信息，本网站可根据运营需要及本网站规则及进行披露和使用：
            <br/>用户在本网站注册后形成的公共档案信息，其中仅包括用户自定义的网站用户名（须不与他人在先注册之用户名相同）、注册时间，以及用户选择公开的其他信息。
            <br/>用户在使用本网站平台提供的搜索服务时输入的关键字信息。
            <br/>用户违反法律规定或违反本网站规则的行为及本网站对用户采取的措施。
          </p>
          <h4 class="mt20">加密</h4>
          <p class="article-item">
            <span class="strong">我们会对您的信息进行脱敏处理或加密处理等匿名化处理的合理措施（包括但不限于数据加密、隐藏姓名、保存安全日志、防范恶意代码等），经过处理的信息的主体将无法被识别。在不透露您个人信息的前提下，我们有权对匿名化处理后的用户数据进行分析利用。</span>
          </p>

          <h4 class="mt20">信息存储和交换。</h4>
          <p class="article-item">
            本网站不保证存储、修改和删除用户向本网站提交的任何信息，同时对于长时间未被登录的用户账户，本网站有权予以终止，并删除该账户内的所有信息。 保存在本网站的服务器上的有关用户的信息和资料可能被传送至用户所在国家、地区或本网站收集信息和资料所在地及之外的地区并根据本协议规定的披露规则被访问和展示。
            </br>Cookies的使用。Cookies是指本网站为了辨别用户身份而储存在用户本地终端上的数据（通常被加密）。
            </br>在用户未拒绝接受cookies的情况下，本网站可能会在用户的计算机上设定或取用cookies，以便用户能登录或使用依赖于cookies的本网站平台服务或功能。本网站使用cookies可为用户提供更加周到的个性化服务，包括推广服务。
            </br>用户有权选择接受或拒绝接受cookies。用户可以通过修改浏览器设置的方式拒绝接受cookies。但如果用户选择拒绝接受cookies，则用户可能无法登录或使用依赖于cookies的本网站平台服务或功能。
            </br>本网站通过cookies所取得的有关信息，将适用本隐私权保护政策。
          </p>

          <h4 class="mt20">信息储存</h4>
          <p class="article-item">
            前述信息存放地域为中华人民共和国境内。</br>
            我们不会收集与业务合作或为达成双方目的无关的个人信息。我们只会在实现服务所需的必要时间内保留您的信息，我们将结合法律规定和与贵司合作的合理需要评估最少留存期限，前述最少留存期限届满时，我们将对个人信息销毁、删除或脱敏处理（脱敏处理后的信息将无法复原且无法识别出个人信息）。
          </p>

          <h4 class="mt20">联系我们</h4>
          <p class="article-item">
            如果您对我们的隐私政策或信息处理有任何疑问，您可通过以下方式联系我们：
          </p>
          <p class="article-item">公司名称：聘聘云（上海）智能科技有限公司
            </br>注册地址：上海市崇明区长兴镇江南大道1333弄11号楼2866室
            </br>联系电话：400-6688-069
          </p>
          <p class="article-item">
            为更及时有效的解决您的问题，请在联系我们时提供：
            </br>①您的姓名（名称）及联系方式；
            </br>②您的详细书面要求。
          </p>
        </div>
      </div>
      <div v-if="!isProtocol" slot="footer" :class="{'no-drop': !loginFlag}">
        <a v-if="type === 2" href="javascript:;" @click="onLogin(2)" :loading="isBtnLoading" class="login-btn"><i class="h5pp h5pp-icon-right-circle"></i>登录</a>
        <a v-else-if="type === 3" href="javascript:;" @click="bindPhoneEmail()" :loading="isBtnLoading" class="login-btn"><i class="h5pp h5pp-icon-right-circle"></i>绑定</a>
        <a v-else-if="type === 4" href="javascript:;" @click="onRegister" :loading="isBtnLoading" class="login-btn"><i class="h5pp h5pp-icon-right-circle"></i>注册</a>
        <a v-else-if="type === 5" href="javascript:;" @click="bindPhoneEmail()" :loading="isBtnLoading" class="login-btn"><i class="h5pp h5pp-icon-right-circle"></i>绑定</a>
        <a v-else href="javascript:;" @click="onLogin(1)" :loading="isBtnLoading" class="login-btn"><i class="h5pp h5pp-icon-right-circle"></i>登录</a>
        <p class="agreement-box">
          <el-checkbox v-model="checkedFlag">已阅读并同意</el-checkbox>
          <a href="javascript:;" @click="isProtocol = true">《聘聘云隐私协议》</a>
        </p>
        <div v-if="type === 3" class="jump-box"><span @click="handleSkip">跳过，稍后再说</span></div>
      </div>
      <div v-else slot="footer" class="protocol-footer">
        <a href="javascript:;" class="btn-ok" @click="checkedFlag = true; isProtocol = false;"><i class="h5pp h5pp-icon-right-circle"></i> 确认</a>
      </div>
    </el-dialog>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { local } from '@/utils'
  import { Dialog, Form, FormItem, Input, Button, Message, Checkbox } from 'element-ui'

  export default {
    props: {
      dialogData: Object
    },
    components: {
      [Dialog.name]: Dialog,
      [Form.name]: Form,
      [FormItem.name]: FormItem,
      [Input.name]: Input,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox,
      [Message.name]: Message
    },
    data() {
      return {
        type: 1, // 1:手机号登录 2:邮箱登录 3:绑定手机号 4:邮箱注册/找回密码 5:绑定邮箱
        operateType: 1,
        secondsCount: 60,
        isBtnLoading: false,
        loginForm: {
          mobile: '',
          verifyCode: '',
          email: '',
          password: '',
          verifyCodeEmail:''
        },
        tabNav: [
          {name: '手机登录', type: 1},
          {name: '邮箱登录', type: 2},
        ],
        isProtocol: false,
        checkedFlag: false
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      mobile() {
        return (JSON.parse(local.get('user') || '{}')).mobile || '';
      },
      email() {
        return (JSON.parse(local.get('user') || '{}')).email || '';
      },
      secondFlag() {
        if(this.loginForm.mobile && this.loginForm.mobile.length>=11) {
          return true;
        } else {
          return false;
        }
      },
      secondEmailFlag() {
        if(this.loginForm.email && /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_\-\.]+\.([a-zA-Z]{2,4})$/.test(this.loginForm.email)) {
          return true;
        } else {
          return false;
        }
      },
      loginFlag() {
        if(this.checkedFlag 
          || ([1, 3].includes(this.type) && (this.loginForm.mobile || this.loginForm.verifyCode)) 
          || (this.type === 2 && (this.loginForm.email || this.loginForm.password)) 
          || ([4, 5].includes(this.type) && (this.loginForm.email || this.loginForm.password || this.loginForm.verifyCodeEmail))
        ){
          return true;
        }
        return false
      },
      title: {
        get() {
          switch(this.type) {
            case 1:
            return '手机验证码登录';
            case 2:
              return '邮箱登录';
            case 3:
              return '绑定手机号';
            case 4:
              return '邮箱注册/找回密码';
            case 5:
              return '邮箱注册/找回密码';
            default:
              return '手机验证码登录';
          }
        },
        set() {}
      }
    },
    created() {
      if(this.dialogData.type) {
        this.type = this.dialogData.type;
      }
    },
    methods: {
      back() {
        if(this.type === 4 && !this.isProtocol) {
          this.type = 2;
        }
        if(this.isProtocol) {
          this.isProtocol = false;
        }
      },
      onSendCode(type) {
        if(this.operateType === 2) {
          return;
        }
        if(type === 1) {
          this.$refs.loginForm.validateField('mobile', (errMsg => {
            if(errMsg) {
              return;
            }
            this.$http.get('/api/auth/verify/code/send.json', {params: {
              mobile: this.loginForm.mobile,
              sendType: 6
            }});
            this.operateType = 2;
            this.cycleCallTime();
          }));
        } else { //邮件验证码
          this.$refs.loginForm.validateField('email', (errMsg => {
            if(errMsg) {
              return;
            }
            this.$http.post('/api/internal/recom/person/verify/email/send.json', {
              email: this.loginForm.email,
              websiteId: this.uccnInfo.id,
              sendType: this.type === 4 ? 1 : 2 // 注册：1，绑定：2
            });
            this.operateType = 2;
            this.cycleCallTime();
          }));
        }
      },
      cycleCallTime() {
        let timer = setTimeout(() => {
          if(this.secondsCount <= 0) {
            this.operateType = 3;
            this.secondsCount = 60;
            clearTimeout(timer);
            return;
          }
          this.secondsCount -= 1;
          this.cycleCallTime();
        }, 1e3);
      },
      onLogin(type) {
        let url = '';
        let params = {};
        if(type === 2) {
          url = '/api/internal/recom/person/email/login.json';
          params = {
            email: this.loginForm.email,
            password: this.loginForm.password,
            websiteId: this.uccnInfo.id
          }
        } else {
          url = '/api/internal/recom/person/login.json';
          params = {
            mobile: this.loginForm.mobile,
            verifyCode: this.loginForm.verifyCode,
            websiteId: this.uccnInfo.id
          }
        }
        this.$refs.loginForm.validate(valid => {
          if(!valid) {
            return;
          }
          if(!this.checkedFlag) {
            Message.error('请勾选并阅读《聘聘云隐私协议》');
            return
          }
          this.isBtnLoading = true;
          this.$http.post(url, params).then(data => {
            // 校园大使未激活跳转激活页面
            if(data.a && data.b && data.sign) {
              this.$router.push({
                name: 'userAuthAmbassador',
                query: {
                  a: data.a,
                  b: data.b,
                  sign: data.sign
                }
              });
              return;
            }
            if(data.errCode === 'b_account_auth_0005' || data.errCode === 'b_account_auth_0004') {
              Message.error(data.errMessage);
              return;
            }
            if(data.status === 'email_00002') { //邮箱登录账号或密码错误
              console.log('email_00002', data);
            }
            if(data) {
              this.$store.commit('SET_USER_INFO', data);
              location.reload();
            }
          }).finally(() => {
            this.isBtnLoading = false;
          });
        });
      },
      bindPhoneEmail() { // 绑定
        this.$refs.loginForm.validate(valid => {
          if(!valid) {
            return;
          }
          if(!this.checkedFlag) {
            Message.error('请勾选并阅读《聘聘云隐私协议》');
            return
          }
          this.$http.post('/api/internal/recom/person/info/complete.json', {
            mobile: this.type === 3 ? this.loginForm.mobile : this.mobile,
            email: this.type !== 3 ? this.loginForm.email : this.email,
            verifyCode: this.loginForm.verifyCode,
            password: this.loginForm.password,
            websiteId: this.uccnInfo.id,
            emailFlag: this.type === 3 ? 0 : 1 //邮箱：1，手机：0
          }).then(data => {
            // 校园大使未激活跳转激活页面
            if(data.a && data.b && data.sign) {
              this.$router.push({
                name: 'userAuthAmbassador',
                query: {
                  a: data.a,
                  b: data.b,
                  sign: data.sign
                }
              });
              return;
            }
            if(data) {
              this.$store.commit('SET_USER_INFO', data);
              location.reload();
            }
          }).finally(() => {
            this.isBtnLoading = false;
          });
          local.remove('bindPhoneFlag');
        });
      },
      onRegister() { // 邮箱注册
        this.$refs.loginForm.validate(valid => {
          if(!valid) {
            return;
          }
          if(!this.checkedFlag) {
            Message.error('请勾选并阅读《聘聘云隐私协议》');
            return
          }
          this.$http.post('/api/internal/recom/person/email/register.json', {
            email: this.loginForm.email,
            verifyCode: this.loginForm.verifyCodeEmail,
            password: this.loginForm.password,
            websiteId: this.uccnInfo.id
          }).then(data => {
            // 校园大使未激活跳转激活页面
            if(data.a && data.b && data.sign) {
              this.$router.push({
                name: 'userAuthAmbassador',
                query: {
                  a: data.a,
                  b: data.b,
                  sign: data.sign
                }
              });
              return;
            }
            if(data) {
              this.$store.commit('SET_USER_INFO', data);
              location.reload();
              if(!this.mobile) {
                local.set('bindPhoneFlag', true);
              }
            }
          }).finally(() => {
            this.isBtnLoading = false;
          });
        });
      },
      handleEmailLogin(type) {
        this.type = type;
        this.$refs.loginForm.clearValidate();
      },
      handleSkip() {
        this.dialogData.isShow = false;
        local.remove('bindPhoneFlag');
      }
    },
    watch: {
      type(val) {
        switch(val) {
          case 1:
            this.title = '手机验证码登录';
            break;
          case 2:
            this.title = '邮箱登录';
            break;
          case 3:
            this.title = '绑定手机号';
            break;
          case 4:
            this.title = '邮箱注册/找回密码';
            break;
          case 5:
            this.title = '邮箱注册/找回密码';
            break;
          default:
            this.title = '手机验证码登录';
        }
      }
    }
  }
</script>
